import consumer from "./consumer"

consumer.subscriptions.create({
  channel: "ProviderPaymentChannel",
  purchase_id: window.location.pathname.split("/").pop()
}, {
  connected() {
    console.log('connected_to_provider_payment_channel');
  },

  received(data) {
    console.log(data);

    switch (data.state) {
      case 'finished':
        window.location.reload();
        break;
      case 'failed_provider_payment':
        alert('No se pudo completar el pago a proveedor');
        window.location.reload();
        break;
      case 'failed_delete_provider_payment':
        alert('No se pudo eliminar el pago a proveedor');
        window.location.reload();
        break;
      case 'pending':
        document.querySelector('#provider-payment-spinner').classList.remove('d-none');
        break
      case 'delete_pending':
        document.querySelector('#delete-provider-payment-spinner').classList.remove('d-none');
        break;
    }
  },

  loaderHtml() {
    return `
      <span class="spinner-border spinner-border-sm" role="status"></span>
    `;
  }
});