import consumer from '../channels/consumer';
import ApplicationController from './application_controller';

const CHANNEL_NAME = 'PurchaseChannel';
const ERROR_MESSAGES = {
  failed_reservation: 'No se pudo completar la compra.',
  failed_payment: 'Se intentó completar la compra pero hubo un error con el pago.',
  failed_update: 'No se pudo actualizar la compra.',
  failed_cancellation: 'Se intentó cancelar el movimiento pero hubo un error.',
};

export default class extends ApplicationController {
  static targets = ['container', 'spinner'];

  connect() {
    this.subscribe(this.purchaseId);
  }

  subscribe(purchaseId) {
    consumer.subscriptions.create(
      {
        channel: CHANNEL_NAME,
        purchase_id: purchaseId,
      },
      {
        connected() {
          console.log('connected_to_purchase_channel', purchaseId);
        },

        received(data) {
          console.log(`Data received from ${CHANNEL_NAME}`, data);

          if (data.state == 'finished') {
            window.location.reload();
            return;
          }

          if (!ERROR_MESSAGES[data.state]) {
            return;
          }

          alert(ERROR_MESSAGES[data.state]);
          window.location.reload();
        },
      }
    );
  }

  handleClick(event) {
    this.insertTemplate(this.spinnerTarget, this.containerTarget);
  }

  get purchaseId() {
    return this.data.get('purchaseId');
  }
}
