$(function() {

  // $('.date-range').daterangepicker({
  //   opens: 'center',
  //   autoUpdateInput: false,
  //   autoApply: true,
  //   locale: {
  //     format: 'DD/MM/YYYY',
  //     cancelLabel: 'Clear'
  //   },
  // });

  // $('.date-range').on('apply.daterangepicker', function(ev, picker) {
  //   $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
  // });

  // $('.date-range').on('cancel.daterangepicker', function(ev, picker) {
  //   //do something, like clearing an input
  //   $('.date-range').val('');
  // });
});
