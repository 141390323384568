import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["providerPaymentFormContainer", "providerPaymentFormTemplate"];

  toggleForm(e) {
    e.preventDefault();

    if (this.isFormVisible) {
      this.hideForm();
    } else {
      this.showForm();
    }
  }

  showForm() {
    this.providerPaymentFormContainerTarget.insertAdjacentHTML(
      "beforeend",
      this.providerPaymentFormTemplateTarget.innerHTML
    );
    this.isFormVisible = true;
  }

  hideForm() {
    this.providerPaymentFormContainerTarget.removeChild(this.providerPaymentFormContainerTarget.lastElementChild);
    this.isFormVisible = false;
  }

  set isFormVisible(value) {
    this.data.set("isFormVisible", value);
  }

  get isFormVisible() {
    return this.data.get("isFormVisible") === "true";
  }
}