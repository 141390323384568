import { Controller } from "stimulus";

export default class extends Controller {
  // aqui establecemos los targets disponibles para este controller
  static targets = ["formTemplate", "actionLink"];

  toggleForm(e) {
    e.preventDefault();

    if (this.isFormVisible) {
      this.hideForm();
    } else {
      this.showForm();
    }
  }

  showForm() {
    this.element.insertAdjacentHTML(
      "beforeend",
      this.formTemplateTarget.innerHTML
    );
    this.isFormVisible = true;
    this.actionLinkTarget.innerHTML = "Cancelar";
  }

  hideForm() {
    this.element.removeChild(this.element.lastElementChild);
    this.actionLinkTarget.innerHTML = "Modificar Viaje";
    this.isFormVisible = false;
  }

  // con this.data guardamos el estado del controller, esto literalemte se agrega como un data-prop en el html
  set isFormVisible(value) {
    this.data.set("isFormVisible", value);
  }

  get isFormVisible() {
    return this.data.get("isFormVisible") === "true";
  }
}
