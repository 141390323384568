$(document).on('turbolinks:load', () => {
  // let datepickerConfig = {
  //   autoApply: false,
  //   maxDate: moment(),
  // };

  // $('input[name="datepicker"]').daterangepicker(datepickerConfig);

  $('#download-report-btn').click(_ => {
    let datepicker = $('input[name="datepicker"]');
    let datepickerData = datepicker.data('daterangepicker');

    let starts
    let ends
    if (datepicker.val().length) {
      starts = datepickerData.startDate.startOf('day').format("YYYY-MM-DD HH:mm:ss");
      ends = datepickerData.endDate.endOf('day').format("YYYY-MM-DD HH:mm:ss");
    }

    const agencyIds = $('#agencies-select').val();

    const config = { "method": "POST" };
    const params = $.param({agency_ids: agencyIds, starts: starts, ends: ends});

    fetch(`/reports?${params}`, config)
      .then(res => res.text().then(data => ({code: res.status, url: data})))
      .then(res => {
        console.log(res)
        if (res.code == 200) {
          window.open(res.url);
        } else {
          alert("Report could not be created");
        }
      })
      .catch(x => alert("Report cant be created"));
  });
  
});
