import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["partialFormTemplate", "actionLink"];

  toggleForm(e) {
    e.preventDefault();
    if (this.isFormVisible) {
      this.hideForm();
    } else {
      this.showForm();
    }
  }

  showForm() {
    this.element.insertAdjacentHTML(
      "beforeend",
      this.partialFormTemplateTarget.innerHTML
    );
    this.isFormVisible = true;
    this.actionLinkTarget.innerHTML = "Cancelar";
  }

  hideForm() {
    this.element.removeChild(this.element.lastElementChild);
    this.actionLinkTarget.innerHTML = "<strong>Reembolso parcial</strong>";
    this.isFormVisible = false;
  }

  set isFormVisible(value) {
    this.data.set("isFormVisible", value);
  }

  get isFormVisible() {
    return this.data.get("isFormVisible") === "true";
  }
}
