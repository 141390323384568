import { Controller } from 'stimulus';

export default class extends Controller {
  insertTemplate(template, container) {
    container.innerHTML = "";
    container.insertAdjacentHTML(
      "beforeend",
      template.innerHTML
    );
  }
}