import { Controller } from "stimulus";
import axios from 'axios';
import autocomplete from 'autocomplete.js';

export default class extends Controller {
  static targets = ["map", "field", "latitude", "longitude", 'city', 'hiddenCity',];
  static values = { lat: Number, long: Number }

  connect() {
    if (typeof (google) != "undefined") {
      this.initMap()
    }

    try {
      this.addAutocomplete(this.cityTarget, this.hiddenCityTarget)
    } catch (e) {
      console.info('Some targets were not found.')
    }
  }

  initMap() {
    const latLng = {
      lat: this.latValue || 22.77,
      lng: this.longValue || -101.17,
    };

    this.map = new google.maps.Map(this.mapTarget, {
      center: new google.maps.LatLng(latLng),
      zoom: (!this.latValue ? 5 : 17)
    })

    if (document.forms.length) {
      this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget)
      this.autocomplete.bindTo('bounds', this.map);
      this.autocomplete.setFields(['address_components', 'geometry', 'icon', 'name'])
      this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))
    }

    this.marker = new google.maps.Marker({
      map: this.map,
      position: latLng,
      draggable: true,
    })
  }

  placeChanged() {
    let place = this.autocomplete.getPlace()

    if (!place.geometry) {
      window.alert("No hay resultados que coincidan")
      return
    }

    if (place.geometry.viewport) {
      this.map.fitBounds(place.geometry.viewport)
    } else {
      this.map.setCenter(place.geometry.location)
      this.map.setZoom(17)
    }

    this.marker.setPosition(place.geometry.location)
    this.marker.setVisible(true)

    this.latitudeTarget.value = place.geometry.location.lat()
    this.longitudeTarget.value = place.geometry.location.lng()
  }

  keydown(event) {
    if (event.key == "Enter") {
      event.preventDefault();
    }
  }

  addAutocomplete(inputTarget, hiddenTarget) {
    const ac = autocomplete(inputTarget, {hint: false}, [
      {
        source: this.source(),
        debounce: 200,
        templates: {
          suggestion: function (suggestion) {
            return `${suggestion.name}, ${suggestion.state}`;
          },
        },
      },
    ]).on('autocomplete:selected', (event, suggestion, dataset, context) => {
      ac.autocomplete.setVal(suggestion.name);
      hiddenTarget.value = suggestion.name;
    });
  }

  source() {
    const url = '/routes/search_city?place=';
    return (q, callback) => {
      axios.get(url + q).then((response) => {
        callback(response.data);
      });
    };
  }
}
