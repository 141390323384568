import axios from 'axios';
import autocomplete from 'autocomplete.js';
import ApplicationController from './application_controller';
import { cssNumber } from 'jquery';

export default class extends ApplicationController {
  static targets = ['cityField', 'cityHideInput'];

  source() {
    const url = this.placesUrl;
    return (q, callback) => {
      axios.get(url, { params: { q } }).then((response) => {
        callback(response.data.filter(({ result_type }) => result_type == 'city'));
      });
    };
  }

  connect() {
    this.ac = autocomplete(this.cityFieldTarget, { hint: false }, [
      {
        source: this.source(),
        debounce: 200,
        templates: {
          suggestion: function (suggestion) {
            return `${suggestion.display}, ${suggestion.city_name}`;
          },
        },
      },
    ]).on('autocomplete:selected', (event, suggestion, dataset, context) => {
      if (document.activeElement === event.target) {
        this.ac.autocomplete.setVal(suggestion.display);
        this.cityHideInputTarget.value = suggestion.slug;
      }
      window.location.href = `/cities/${suggestion.display}`;
    });
  }

  get placesUrl() {
    return this.data.get('placesUrl');
  }
}
